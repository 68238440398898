var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "align-center": "", "justify-center": "", row: "" } },
    [
      _c(
        "v-flex",
        {
          staticClass: "text-sm-center",
          attrs: { xs10: "", md8: "", lg6: "", xl4: "" }
        },
        [
          _c("header", [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("@/assets/images/IMG-logo.svg") }
            })
          ]),
          _c(
            "div",
            { staticClass: "elementPanel elementPanel--shadowed" },
            [
              _vm.showExplanation
                ? [
                    _c("h1", [_vm._v("Uw account instellen")]),
                    _c("p", [
                      _vm._v(
                        ' Volg de onderstaande stappen om de "Multi Factor Authentication" (MFA) in te stellen. Dit is een extra beveiligingsstap tijdens het inloggen, waarbij u (naast gebruikersnaam en wachtwoord) een extra code ontvangt op uw smartphone. '
                      )
                    ]),
                    _c("ol", { staticClass: "stepsList" }, [
                      _c("li", { staticClass: "stepsList__item" }, [
                        _c("p", { staticClass: "item__stepTitle" }, [
                          _vm._v("Download de authenticatie app")
                        ]),
                        _c("p", [
                          _vm._v(
                            " Om uw account in te stellen heeft u een authenticatie app nodig op uw telefoon. Deze kunt u downloaden in de Google store of via de Appstore. "
                          )
                        ]),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href:
                                "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=nl"
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "authenticator",
                              attrs: {
                                alt: "google authenticator",
                                src: require("@/assets/images/google-play.svg")
                              }
                            })
                          ]
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href:
                                "https://apps.apple.com/nl/app/google-authenticator/id388497605"
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "authenticator",
                              attrs: {
                                alt: "google authenticator",
                                src: require("@/assets/images/app-store.svg")
                              }
                            })
                          ]
                        )
                      ]),
                      _c("li", { staticClass: "stepsList__item" }, [
                        _c("p", { staticClass: "item__stepTitle" }, [
                          _vm._v("Stel je account in middels het formulier")
                        ]),
                        _c("p", [
                          _vm._v(
                            "Vul het formulier in op basis van de gegeven aanwijzingen."
                          )
                        ])
                      ])
                    ]),
                    _c(
                      "v-flex",
                      [
                        _c(
                          "MiButton",
                          {
                            staticClass: "float-left",
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                _vm.showExplanation = false
                              }
                            }
                          },
                          [_vm._v("Stel MFA in")]
                        ),
                        _c(
                          "MiButton",
                          {
                            staticClass: "float-right",
                            attrs: { color: "primary-light", outline: true },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.logout($event)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("auth.logout")) + " ")]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              !_vm.showExplanation
                ? [
                    _c("h2", [
                      _vm._v("Stel je Multi-factor authenticatie (MFA) in")
                    ]),
                    _c("p", [
                      _vm._v(
                        " Bij het instellen van de MFA scant u eerst de QR code. Vul in het eerste veld de 6-cijferige code in. Wacht tot er (automatisch) een nieuwe 6-cijferige code verschijnt en vul deze in het tweede veld in. "
                      )
                    ]),
                    _c(
                      "v-layout",
                      { staticClass: "v-form__inner", attrs: { wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          _c("h3", [
                            _vm._v(
                              "1. Scan de QR code met de ingestelde app op uw telefoon"
                            )
                          ]),
                          _c("img", { attrs: { src: _vm.qrImage } })
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("h3", [_vm._v("2. Vul het eerste getal in")]),
                            _c("v-text-field", {
                              attrs: {
                                required: "",
                                label: "Vul de eerste 6-cijferige code",
                                type: "number",
                                maxlength: "6"
                              },
                              model: {
                                value: _vm.firstToken,
                                callback: function($$v) {
                                  _vm.firstToken = $$v
                                },
                                expression: "firstToken"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("h3", [_vm._v("3. Vul het tweede getal in")]),
                            _c("v-text-field", {
                              attrs: {
                                required: "",
                                label: "Vul de tweede 6-cijferige code",
                                disabled: _vm.firstToken.length !== 6,
                                type: "number",
                                maxlength: "6"
                              },
                              model: {
                                value: _vm.secondtoken,
                                callback: function($$v) {
                                  _vm.secondtoken = $$v
                                },
                                expression: "secondtoken"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-layout",
                      { attrs: { wrap: "", "justify-space-between": "" } },
                      [
                        _c(
                          "v-flex",
                          { staticClass: "flex-starving" },
                          [
                            _c(
                              "MiButton",
                              {
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    _vm.showExplanation = true
                                  }
                                }
                              },
                              [_vm._v("Terug")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { staticClass: "flex-starving" },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: { disabled: _vm.checkTokens },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.createMfa($event)
                                  }
                                }
                              },
                              [_vm._v("MFA Instellen")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }