import { Component, Vue } from 'vue-property-decorator';
import { identifyHostname } from '@/support/Client';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { MiPasswordVisibility, MiPasswordIcons } from '@/components/mi-password/MiPassword';
import { User } from '@/models/User';

@Component<SetMfa>({})
export default class SetMfa extends Vue {
  protected oAuthServer: string = identifyHostname() || '';

  protected oAuthClientId = 1;

  protected oAuthSecret = 'secret';

  protected oAuthScope = '*';

  protected qrImage = '';

  protected firstToken = '';

  protected secondtoken = '';

  protected secret = '';

  protected showExplanation = true;

  protected isSubmitted = false;

  protected redirect = false;

  protected visibility: MiPasswordVisibility = {
    ruleList: true,
    showPassword: true,
  }

  protected icons: MiPasswordIcons = {
    password: 'lock_open',
    confirmation: 'lock_open',
  };

  protected mounted() {
    this.initialize();
  }

  protected initialize() {
    this.fetchQRCode();
  }

  get user(): User {
    return this.$store.state.Auth;
  }

  protected fetchQRCode() {
    if (! this.user) return;
    new User()
      .getQR(this.user.uuid)
      .then((response: any) => {
        this.qrImage = response.qr_image;
        this.secret = response.secret;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected createMfa() {
    const payload = {
      secret: this.secret,
      token1: this.firstToken,
      token2: this.secondtoken,
    };

    this.user
      .storeQr(payload, this.user.uuid)
      .then(() => {
        new User().current().then((user: User) => {
          this.$store.dispatch('userAuthenticated', user);
          this.$router.push('/reports');
        });
      })
      .catch((error: AxiosError) => {
        this.firstToken = '';
        this.secondtoken = '';
        ErrorHandler.network(error);
      });
  }

  protected logout() {
    this.$store.dispatch('userDeauthenticated');
    this.$router.push('/login');
  }

  protected canSubmit(password: string, confirmation: string) {
    if (! password || ! confirmation || ! this.getEmail() || ! this.getToken()) {
      return false;
    }
    return password === confirmation;
  }

  protected get checkTokens() {
    if (this.firstToken.length !== 6 || this.secondtoken.length !== 6) return true;
    return false;
  }

  protected redirectRoute() {
    this.$store.dispatch('userDeauthenticated');
    this.$router.push('/login');
  }

  protected navigateToUrl(url: string) {
    return this.$router.push(url);
  }

  public getToken() {
    return this.$route.query.token;
  }

  public getEmail() {
    return this.$route.query.email;
  }
}
